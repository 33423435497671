var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[(_vm.multiple)?[_c('v-row',{staticClass:"fix-row-space"},[_c('v-col',{attrs:{"cols":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Datum","readonly":""},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,594268234),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"min":_vm.dateToday,"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('ValidationProvider',{attrs:{"name":"Von","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Von","type":"time","error-messages":errors},on:{"blur":_vm.onBlur},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})]}}],null,false,3817107949)})],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('ValidationProvider',{attrs:{"name":"Bis","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Bis","type":"time","error-messages":errors},on:{"blur":_vm.onBlur},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})]}}],null,false,2739566671)})],1),_c('v-col',{attrs:{"cols":"auto","align-self":"center"}},[_c('v-btn',{attrs:{"text":"","icon":""},on:{"click":_vm.removeDate}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1)]:[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Datum","readonly":""},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"min":_vm.dateToday,"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-row',{staticClass:"fix-row-space"},[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Von","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Von","type":"time","error-messages":errors},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Bis","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Bis","type":"time","error-messages":errors},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})]}}])})],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }