<template>
  <v-row>
    <v-col cols="12" sm="6">
      <h2 class="title">Teilnehmer</h2>
      <p class="text-body-2">Richtet sich dein Angebot an Kinder, wähle bitte die relevante Altersspanne aus.</p>
      <p class="text-body-2">Ist dein Angebot ausschliesslich für Erwachsene geeignet, überspringe bitte die Altersangabe.</p>
      <p class="text-body-2">Dreht sich dein Erwachsenenangebot inhaltlich um das Thema Kind, wähle auch hier bitte die themenbezogene Altersangabe entsprechend aus.</p>
    </v-col>
    <v-col cols="12" sm="6">
      <ValidationProvider name="Teilnehmer" rules="required" slim v-slot="{ errors }">
        <v-radio-group v-model="parents" :error-messages="errors">
          <v-radio label="für Kinder" value="NONE" />
          <v-radio label="für Kinder mit Erwachsenen" value="REQUIRED" />
          <v-radio label="für Erwachsene" value="ONLY" />
        </v-radio-group>
      </ValidationProvider>
      <div>Alter</div>
      <ValidationProvider name="Alterskategorie" rules="required" slim v-slot="{ errors }" v-if="parents !== 'ONLY'">
        <input type="hidden" v-model="ages" />
        <div v-for="(error, index) in errors" :key="`error-${index}`" class="caption red--text">
          {{ error }}
        </div>
      </ValidationProvider>
      <v-row dense>
        <v-col align-self="center">
          <v-btn rounded outlined small color="primary" @click="toggleAgeGroup(0, 12)">{{ ages.length > 12 ? "Keine" : "Alle" }}</v-btn>
        </v-col>
      </v-row>
      <v-row dense v-for="(years, index) in groupedYears" :key="`years-${index}`" :class="index !== groupedYears.length - 1 ? 'mb-5 mb-md-0' : null">
        <v-col cols="4" sm="12" md="4" lg="3" align-self="center">
          <v-btn rounded outlined small color="primary" @click="toggleAgeGroup(years[0], years[years.length - 1])">{{ years[0] }} - {{ years[years.length - 1] }} Jahre</v-btn>
        </v-col>
        <v-col cols="auto" v-for="(year, i) in years" :key="`year-${i}`">
          <v-btn class="ages-btn" fab small color="primary" depressed :dark="ages.includes(year)" :outlined="!ages.includes(year)" @click.stop="toggleAge(year)">
            {{ year === 12 ? `${year}+` : year }}
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { ValidationProvider } from 'vee-validate';
import { range } from '@/helpers/range';

const { mapFields } = createHelpers({
  getterType: 'activity/field',
  mutationType: 'activity/updateField',
});

export default {
  components: { ValidationProvider },

  data: () => ({
    groupedYears: [[0, 1, 2, 3], [4, 5, 6, 7], [8, 9, 10, 11, 12]],
  }),

  computed: {
    ...mapFields(['parents', 'ages']),
  },

  methods: {
    toggleAge(year) {
      if (this.ages.includes(year)) {
        this.ages = this.ages.filter((item) => item !== year);
      } else {
        this.ages = [year, ...this.ages];
      }
      this.ages.sort((a, b) => a - b);
    },
    toggleAgeGroup(from, to) {
      const ages = range(from, to, 1);
      if (ages.every((age) => this.ages.includes(age))) {
        this.ages = this.ages.filter((age) => !ages.includes(age));
      } else {
        this.ages = [...ages, ...this.ages];
      }
      this.ages.sort((a, b) => a - b);
    },
  },
};
</script>

<style lang="scss" scoped>
.ages-btn.v-btn:focus:before {
  opacity: 0;
}
</style>
