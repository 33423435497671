var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Beginn Veranstaltungsreihe","readonly":""},model:{value:(_vm.dtstartFormatted),callback:function ($$v) {_vm.dtstartFormatted=$$v},expression:"dtstartFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dtstartMenu),callback:function ($$v) {_vm.dtstartMenu=$$v},expression:"dtstartMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.dateToday,"max":_vm.until,"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.dtstartMenu = false}},model:{value:(_vm.dtstart),callback:function ($$v) {_vm.dtstart=$$v},expression:"dtstart"}})],1),_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Ende Veranstaltungsreihe","readonly":""},model:{value:(_vm.untilFormatted),callback:function ($$v) {_vm.untilFormatted=$$v},expression:"untilFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.untilMenu),callback:function ($$v) {_vm.untilMenu=$$v},expression:"untilMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.dtstart,"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.untilMenu = false}},model:{value:(_vm.until),callback:function ($$v) {_vm.until=$$v},expression:"until"}})],1),_c('v-select',{attrs:{"label":"Wiederholung","items":_vm.freqItems},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getFreqText(item)))]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getFreqText(item)))]}}]),model:{value:(_vm.freq),callback:function ($$v) {_vm.freq=$$v},expression:"freq"}}),(_vm.freq === 'CUSTOM')?[_c('v-row',{staticClass:"fix-row-space"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"textfield-select",attrs:{"label":"Wiederhole jeden","type":"number","min":"1"},model:{value:(_vm.interval),callback:function ($$v) {_vm.interval=$$v},expression:"interval"}},[_c('template',{slot:"append"},[_c('v-select',{attrs:{"items":_vm.freqCustomItems,"hide-details":""},model:{value:(_vm.freqCustom),callback:function ($$v) {_vm.freqCustom=$$v},expression:"freqCustom"}})],1)],2)],1),_c('v-col',{attrs:{"cols":"6"}},[(_vm.freqCustom === 'WEEKLY')?[_c('v-select',{attrs:{"label":"Wiederholen am","items":_vm.weekDays,"multiple":""},model:{value:(_vm.byweekdayCustom),callback:function ($$v) {_vm.byweekdayCustom=$$v},expression:"byweekdayCustom"}})]:_vm._e(),(_vm.freqCustom === 'MONTHLY')?[_c('v-select',{attrs:{"items":_vm.monthlyRecurrenceItems},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getMonthlyRecurrenceText(item)))]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getMonthlyRecurrenceText(item)))]}}],null,false,1640652668),model:{value:(_vm.monthlyRecurrence),callback:function ($$v) {_vm.monthlyRecurrence=$$v},expression:"monthlyRecurrence"}})]:_vm._e()],2)],1)]:_vm._e(),_c('v-row',{staticClass:"fix-row-space"},[_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Von","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Von","type":"time","error-messages":errors},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})]}}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('ValidationProvider',{attrs:{"name":"Bis","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Bis","type":"time","error-messages":errors},model:{value:(_vm.data.to),callback:function ($$v) {_vm.$set(_vm.data, "to", $$v)},expression:"data.to"}})]}}])})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }