<template>
  <fragment>
    <Item
      v-for="(openingTime, index) in data.dates"
      :key="`opening-hours-${index}`"
      :index="index"
      :open="openingTime.open"
      :times="openingTime.times"
      @add:data="addData($event, index)"
      @remove:data="removeData($event, index)"
    />
    <v-row>
      <v-col cols="12" sm="6">
        <v-menu v-model="dtstartMenu" :close-on-content-click="false" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dtstartFormatted" label="Von" readonly clearable v-bind="attrs" v-on="on" />
          </template>
          <v-date-picker v-model="dtstart" :min="dateToday" first-day-of-week="1" no-title :max="until" @input="dtstartMenu = false" />
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6">
        <v-menu v-model="untilMenu" :close-on-content-click="false" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="untilFormatted" label="Bis" readonly clearable v-bind="attrs" v-on="on" />
          </template>
          <v-date-picker v-model="until" :min="dtstart || dateToday" first-day-of-week="1" no-title @input="untilMenu = false" />
        </v-menu>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/de';

dayjs.locale('de');

export default {
  components: {
    Item: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/fields/OpeningHours/Item.vue'),
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    dtstartMenu: false,
    untilMenu: false,
  }),

  computed: {
    dateToday() {
      return dayjs().format('YYYY-MM-DD');
    },
    dtstart: {
      get() {
        return this.$props.data.dtstart ? dayjs(this.$props.data.dtstart).format('YYYY-MM-DD') : null;
      },
      set(value) {
        this.$emit('update:data', { dtstart: value ? dayjs(`${value} 00:00`).toDate() : null });
      },
    },
    dtstartFormatted: {
      get() {
        return this.dtstart ? dayjs(this.dtstart).format('DD.MM.YYYY') : null;
      },
      set(value) {
        if (value === null) this.dtstart = null;
      },
    },
    until: {
      get() {
        return this.$props.data.until ? dayjs(this.$props.data.until).format('YYYY-MM-DD') : null;
      },
      set(value) {
        this.$emit('update:data', { until: value ? dayjs(`${value} 00:00`).toDate() : null });
      },
    },
    untilFormatted: {
      get() {
        return this.until ? dayjs(this.until).format('DD.MM.YYYY') : null;
      },
      set(value) {
        if (value === null) this.until = null;
      },
    },
  },

  methods: {
    addData(data, index) {
      this.data.dates[index].open = true;
      this.data.dates[index].times.push(data);
    },
    removeData(data, index) {
      if (data === 'ALL') {
        this.data.dates[index].open = false;
        this.data.dates[index].times = [];
      } else {
        this.data.dates[index].times = this.data.dates[index].times.slice(0, data).concat(this.data.dates[index].times.slice(data + 1, this.data.dates[index].times.length));
      }
    },
  },
};
</script>
