<template>
  <v-row>
    <v-col cols="12" sm="6" offset-sm="6">
      <ValidationProvider name="Umgebung" rules="required" slim v-slot="{ errors }" v-if="locationType !== 'ONLINE'">
        <v-radio-group v-model="surrounding" label="Umgebung" :error-messages="errors">
          <v-radio label="Draussen" value="OUTDOOR" />
          <v-radio label="Drinnen" value="INDOOR" />
          <v-radio label="Beides" value="UNKNOWN" />
        </v-radio-group>
      </ValidationProvider>
      <v-switch v-model="buggyFriendly" label="Buggytauglich" hide-details v-if="locationType !== 'ONLINE'" />
      <div class="mt-10" v-if="activityType !== 'SHOP'">Kosten</div>
      <v-switch v-model="costFree" label="Kostenlos" hide-details v-if="activityType !== 'SHOP'" />
      <template v-if="costFree === false && activityType !== 'SHOP'">
        <v-text-field label="Kosten" v-model="costDescription.DE" />
        <v-text-field label="Ticket URL" v-model="ticketUrl" />
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { ValidationProvider } from 'vee-validate';

const { mapFields } = createHelpers({
  getterType: 'activity/field',
  mutationType: 'activity/updateField',
});

export default {
  components: { ValidationProvider },

  computed: {
    ...mapFields(['activityType', 'locationType', 'surrounding', 'buggyFriendly', 'costFree', 'costDescription', 'ticketUrl']),
  },
};
</script>
