<template>
  <v-row>
    <v-col cols="12" class="text-right">
      <v-btn class="mr-3" text exact :to="$url('/')" :disabled="loading">Abbrechen</v-btn>
      <v-btn text class="mr-3" @click="handleBack" :disabled="loading" v-if="!isFirst">Zurück</v-btn>
      <v-btn type="submit" color="primary" :loading="loading" :disabled="loading">{{ submit }}</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    submit: {
      type: String,
      default: 'Weiter',
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters({
      loading: 'loading/get',
    }),
  },

  methods: {
    handleBack() {
      this.$emit('back');
    },
  },
};
</script>
