<template>
  <fragment>
    <div v-for="(dateObj, i) in data" :key="`multiple-${i}`">
      <SingleDate :data="dateObj" multiple @update:data="updateDateData($event, i)" @remove:date="removeDate($event, i)" @blur="onBlur" />
    </div>
  </fragment>
</template>

<script>
export default {
  components: {
    SingleDate: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/fields/SingleDate.vue'),
  },

  props: {
    data: {
      type: Array,
      required: true,
    },
  },

  methods: {
    updateDateData(data, index) {
      const dates = [...this.$props.data];
      dates[index] = { ...dates[index], ...data };
      this.$emit('update:data', dates);
    },
    removeDate(date, index) {
      this.$emit('remove:date', index);
    },
    onBlur() {
      this.$emit('blur');
    },
  },
};
</script>
