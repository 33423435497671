<template>
  <v-row>
    <v-col cols="12" sm="6">
      <template v-if="exceptionDatesVisible">
        <h2 class="title">Daten ausschliessen</h2>
        <p class="text-body-2">Wähle Daten aus, an denen dein Angebot nicht stattfindet: Feiertage, Ferien, bei Krankheit oder aus anderen Gründen.</p>
        <v-chip-group column multiple>
          <v-chip v-for="(date, index) in exceptionDates" :key="`date-${index}`" close @click="removeDate(date)" @click:close="removeDate(date)">{{ date | formatDate }}</v-chip>
        </v-chip-group>
      </template>
    </v-col>
    <v-col cols="12" sm="6">
      <v-switch v-model="exceptionDatesVisible" label="Daten ausschliessen" />
      <template v-if="exceptionDatesVisible">
        <v-date-picker v-model="exceptionDates" :min="dateToday" multiple first-day-of-week="1" no-title />
      </template>
    </v-col>
  </v-row>
</template>

<script>
import _ from 'lodash';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

dayjs.locale('de');

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data: () => ({
    exceptionDatesVisible: false,
  }),

  computed: {
    exceptionDates: {
      get() {
        return _.map(this.data.exceptionDates, (date) => dayjs(date).format('YYYY-MM-DD'));
      },
      set(value) {
        const dates = _.map(value.sort(), (date) => dayjs(`${date} 00:00`).toDate());
        this.data.exceptionDates = dates;
      },
    },
    dateToday() {
      return dayjs().format('YYYY-MM-DD');
    },
  },

  filters: {
    formatDate(value) {
      if (value) {
        return dayjs(value).format('dd, DD.MM.YYYY');
      }
      return null;
    },
  },

  watch: {
    exceptionDatesVisible: {
      handler(value, oldValue) {
        if (value !== oldValue && value === false) {
          this.exceptionDates = [];
        }
      },
    },
    exceptionDates: {
      handler(value) {
        this.exceptionDatesVisible = value.length > 0;
      },
      immediate: true,
    },
  },

  methods: {
    removeDate(date) {
      this.exceptionDates = this.exceptionDates.filter((d) => d !== date);
    },
  },
};
</script>
