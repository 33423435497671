<template>
  <fragment>
    <template v-if="multiple">
      <v-row class="fix-row-space">
        <v-col cols>
          <v-menu v-model="menu" :close-on-content-click="false" offset-y min-width="290px">
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="dateFormatted" label="Datum" readonly v-bind="attrs" v-on="on" />
            </template>
            <v-date-picker v-model="date" :min="dateToday" first-day-of-week="1" no-title @input="menu = false" />
          </v-menu>
        </v-col>
        <v-col cols="auto">
          <ValidationProvider name="Von" rules="required" slim v-slot="{ errors }">
            <v-text-field label="Von" type="time" v-model="from" :error-messages="errors" @blur="onBlur" />
          </ValidationProvider>
        </v-col>
        <v-col cols="auto">
          <ValidationProvider name="Bis" rules="required" slim v-slot="{ errors }">
            <v-text-field label="Bis" type="time" v-model="to" :error-messages="errors" @blur="onBlur" />
          </ValidationProvider>
        </v-col>
        <v-col cols="auto" align-self="center">
          <v-btn text icon @click="removeDate">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-menu v-model="menu" :close-on-content-click="false" offset-y min-width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="dateFormatted" label="Datum" readonly v-bind="attrs" v-on="on" />
        </template>
        <v-date-picker v-model="date" :min="dateToday" first-day-of-week="1" no-title @input="menu = false" />
      </v-menu>
      <v-row class="fix-row-space">
        <v-col cols="6">
          <ValidationProvider name="Von" rules="required" slim v-slot="{ errors }">
            <v-text-field label="Von" type="time" v-model="from" :error-messages="errors" />
          </ValidationProvider>
        </v-col>
        <v-col cols="6">
          <ValidationProvider name="Bis" rules="required" slim v-slot="{ errors }">
            <v-text-field label="Bis" type="time" v-model="to" :error-messages="errors" />
          </ValidationProvider>
        </v-col>
      </v-row>
    </template>
  </fragment>
</template>

<script>
import dayjs from 'dayjs';
import { ValidationProvider } from 'vee-validate';

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },

  components: { ValidationProvider },

  data: () => ({
    menu: false,
  }),

  computed: {
    dateToday() {
      return dayjs().format('YYYY-MM-DD');
    },
    date: {
      get() {
        return dayjs(this.$props.data.date).format('YYYY-MM-DD');
      },
      set(value) {
        const data = { date: dayjs(`${value} ${this.data.from}:00`).toDate() };
        this.$emit('update:data', data);
      },
    },
    dateFormatted() {
      return dayjs(this.$props.data.date).format('DD.MM.YYYY');
    },
    from: {
      get() {
        return this.$props.data.from;
      },
      set(value) {
        const data = { date: dayjs(`${this.date} ${value}:00`).toDate(), from: value };
        this.$emit('update:data', data);
      },
    },
    to: {
      get() {
        return this.$props.data.to;
      },
      set(value) {
        this.$emit('update:data', { to: value });
      },
    },
  },

  methods: {
    removeDate() {
      this.$emit('remove:date', dayjs(this.data.date).format('YYYY-MM-DD'));
    },
    onBlur() {
      this.$emit('blur');
    },
  },
};
</script>

<style lang="scss" scoped>
.fix-row-space {
  margin-top: -12px;
}
</style>
