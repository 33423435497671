var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('h2',{staticClass:"title"},[_vm._v("Kategorisierung")]),_c('p',{staticClass:"text-body-2"},[_vm._v(" Weise deinem Angebot die am ehesten zutreffenden Begriffe und Attribute zu. "),_c('br'),_vm._v("Dies erlaubt dem Benutzer eine effiziente Suche. ")]),_c('p',{staticClass:"text-body-2"},[_vm._v("Dein Angebot erscheint für den Benutzer durch Anwählen der jeweiligen Filtereinstellung.")])]),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Kategorie","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Kategorie","loading":_vm.loadingCategories,"items":_vm.categories,"item-text":"name.de","item-value":"path","cache-items":"","error-messages":errors,"readonly":_vm.activityType === 'SHOP',"disabled":_vm.activityType === 'SHOP'},model:{value:(_vm.category),callback:function ($$v) {_vm.category=$$v},expression:"category"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Unterkategorie","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Unterkategorie","loading":_vm.loadingSubcategories,"items":_vm.subcategories,"item-text":"name.de","item-value":"path","error-messages":errors},model:{value:(_vm.subCategory),callback:function ($$v) {_vm.subCategory=$$v},expression:"subCategory"}})]}}])}),(_vm.locationType !== 'ONLINE')?[_c('ValidationProvider',{attrs:{"name":"Veranstaltungsort","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"label":"Ort","items":_vm.venueItems,"loading":_vm.loadingVenues,"filter":_vm.searchVenueItems,"item-text":"name","item-value":"path","error-messages":errors},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" "),(data.item.address)?_c('span',{staticClass:"caption grey--text ml-1"},[_vm._v(" "+_vm._s(data.item.address)+", "+_vm._s(data.item.zip)+" "+_vm._s(data.item.city))]):_vm._e()]}},{key:"item",fn:function(data){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(data.item.name))]),(data.item.address)?_c('v-list-item-subtitle',[_vm._v(_vm._s(data.item.address)+", "+_vm._s(data.item.zip)+" "+_vm._s(data.item.city))]):_vm._e()],1)]}}],null,true),model:{value:(_vm.venue),callback:function ($$v) {_vm.venue=$$v},expression:"venue"}})]}}],null,false,778368285)}),(_vm.venue === 'NEW')?[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Name der Organisation","error-messages":errors},model:{value:(_vm.venueName),callback:function ($$v) {_vm.venueName=$$v},expression:"venueName"}})]}}],null,false,1108922321)}),_c('ValidationProvider',{attrs:{"name":"Adresse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Adresse","error-messages":errors},model:{value:(_vm.venueAddress),callback:function ($$v) {_vm.venueAddress=$$v},expression:"venueAddress"}})]}}],null,false,1891703729)}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pr-3",attrs:{"cols":"4"}},[_c('ValidationProvider',{attrs:{"name":"PLZ","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"PLZ","error-messages":errors},model:{value:(_vm.venueZip),callback:function ($$v) {_vm.venueZip=$$v},expression:"venueZip"}})]}}],null,false,276242133)})],1),_c('v-col',{staticClass:"pl-3",attrs:{"cols":"8"}},[_c('ValidationProvider',{attrs:{"name":"Ort","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Ort","error-messages":errors},model:{value:(_vm.venueCity),callback:function ($$v) {_vm.venueCity=$$v},expression:"venueCity"}})]}}],null,false,2772293694)})],1)],1)]:_vm._e()]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }