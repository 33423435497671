<template>
  <v-row dense>
    <v-col cols="2" class="flat-col opening-hour-label">{{ getNameOfDay(index) }}</v-col>
    <v-col cols="10" md="4" class="flat-col opening-hour-checkbox">
      <v-checkbox v-model="isOpen" label="Geöffnet" dense />
    </v-col>
    <v-col cols="12" md="6" class="flat-col">
      <v-row v-for="(time, index) in times" :key="`opening-time-${index}`" dense>
        <v-col class="flat-col">
          <ValidationProvider name="Von" rules="required" slim v-slot="{ errors }">
            <v-text-field label="Von" type="time" v-model="time.from" dense :error-messages="errors" />
          </ValidationProvider>
        </v-col>
        <v-col class="flat-col">
          <ValidationProvider name="Bis" rules="required" slim v-slot="{ errors }">
            <v-text-field label="Bis" type="time" v-model="time.to" dense hide-details :error-messages="errors" />
          </ValidationProvider>
        </v-col>
        <v-col cols="auto" class="flat-col">
          <v-btn small icon @click="addTime" v-if="times.length - 1 === index">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-btn small icon @click="removeTime(index)" v-if="times.length - 1 !== index">
            <v-icon>mdi-minus</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  props: {
    index: {
      type: Number,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
    times: {
      type: Array,
      required: true,
    },
  },

  components: { ValidationProvider },

  computed: {
    isOpen: {
      get() {
        return this.$props.open;
      },
      set(value) {
        if (value) {
          this.$emit('add:data', { from: '09:00', to: '12:00' });
        } else {
          this.$emit('remove:data', 'ALL');
        }
      },
    },
  },

  methods: {
    addTime() {
      this.$emit('add:data', { from: '09:00', to: '12:00' });
    },
    removeTime(index) {
      this.$emit('remove:data', index);
    },
    getNameOfDay(index) {
      const week = ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'];
      return week[index];
    },
  },
};
</script>

<style lang="scss" scoped>
.flat-col {
  padding-top: 0;
  padding-bottom: 0;
}
.opening-hour-checkbox::v-deep .v-input--selection-controls {
  margin-top: 2px;
  margin-bottom: 14px;
}
.opening-hour-label {
  padding-top: 6px;
}
</style>
