<template>
  <v-row>
    <v-col cols="12" sm="6" offset-sm="6">
      <ValidationProvider name="Titel" rules="required|max:50" slim v-slot="{ errors }">
        <v-text-field label="Titel des Angebots" v-model="title.DE" :error-messages="errors" counter maxlength="50" />
      </ValidationProvider>
      <ValidationProvider name="Beschreibung" rules="required" slim v-slot="{ errors }">
        <v-textarea label="Beschreibung des Angebots" v-model="description.DE" auto-grow rows="8" :error-messages="errors" />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { ValidationProvider } from 'vee-validate';

const { mapFields } = createHelpers({
  getterType: 'activity/field',
  mutationType: 'activity/updateField',
});

export default {
  components: { ValidationProvider },

  computed: {
    ...mapFields(['title', 'description']),
  },
};
</script>
