<template>
  <fragment>
    <v-row>
      <v-col cols="12" sm="6">
        <h2 class="title" v-if="dateType === 'OPENINGTIMES'">Öffnungszeiten</h2>
        <h2 class="title" v-else>Termine</h2>
        <p class="text-body-2">Wann findet dein Angebot statt?</p>
        <template v-if="dateType === 'MULTIPLE'">
          <MultipleDates :data="dateData.dates" @update:data="updateDateData" @remove:date="removeDate" @blur="sortMultipleDates" />
        </template>
        <template v-if="dateType === 'RECURRING'">
          <v-chip-group column multiple>
            <v-chip v-for="(date, index) in recurringDates" :key="`date-${index}`" close @click="excludeDate(date)" @click:close="excludeDate(date)">{{ date | formatDate }}</v-chip>
          </v-chip-group>
          <template v-if="recurringDatesMore || recurringDatesLimmit > 25">
            <v-row class="mt-5">
              <v-col cols="auto" v-if="recurringDatesMore">
                <v-btn text @click="recurringDatesLimmit += 25">Weitere Daten anzeigen</v-btn>
              </v-col>
              <v-col cols="auto" v-if="recurringDatesLimmit > 25">
                <v-btn text @click="recurringDatesLimmit -= 25">Weniger anzeigen</v-btn>
              </v-col>
            </v-row>
          </template>
        </template>
      </v-col>
      <v-col cols="12" sm="6">
        <v-row v-if="activityType !== 'SHOP'">
          <v-col cols="6">
            <v-radio-group v-model="dateType">
              <v-radio label="einmalig" value="SINGLE" />
              <v-radio label="wiederholend" value="RECURRING" />
              <v-radio label="an individuellen Daten" value="MULTIPLE" />
              <v-radio label="Öffnungszeiten" value="OPENINGTIMES" />
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <v-radio-group v-model="dateType">
              <v-radio label="jederzeit" value="ANYTIME" />
              <v-radio label="auf Anfrage" value="REQUEST" />
            </v-radio-group>
          </v-col>
        </v-row>
        <template v-if="dateType === 'SINGLE'">
          <SingleDate :data="dateData" @update:data="updateDateData" />
        </template>
        <template v-if="dateType === 'MULTIPLE'">
          <v-date-picker multiple :min="dateToday" first-day-of-week="1" no-title :value="getDatesOnly(dateData.dates)" @click:date="changeDates" />
        </template>
        <template v-if="dateType === 'RECURRING'">
          <RecurringDate :data="dateData" @update:data="updateDateData" />
        </template>
        <template v-if="dateType === 'MULTIPLE' || dateType === 'RECURRING'">
          <v-switch v-model="dateData.startAnytime" label="Einstieg jederzeit möglich" hide-details />
        </template>
        <template v-if="dateType === 'SINGLE' || dateType === 'MULTIPLE' || dateType === 'RECURRING'">
          <Deadline :data="dateData" />
        </template>
        <template v-if="dateType === 'REQUEST'">
          <Request :data="dateData" />
        </template>
        <template v-if="dateType === 'OPENINGTIMES'">
          <OpeningHourList :data="dateData" @update:data="updateDateData" />
        </template>
      </v-col>
    </v-row>
    <template v-if="dateType === 'OPENINGTIMES'">
      <OpeningHourExceptionDates :data="dateData" />
    </template>
  </fragment>
</template>

<script>
import _ from 'lodash';
import { createHelpers } from 'vuex-map-fields';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { RRule } from 'rrule';

dayjs.locale('de');

const { mapFields } = createHelpers({
  getterType: 'activity/field',
  mutationType: 'activity/updateField',
});

export default {
  components: {
    SingleDate: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/fields/SingleDate.vue'),
    MultipleDates: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/fields/MultipleDates.vue'),
    RecurringDate: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/fields/RecurringDate.vue'),
    Deadline: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/fields/Deadline.vue'),
    Request: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/fields/Request.vue'),
    OpeningHourList: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/fields/OpeningHours/List.vue'),
    OpeningHourExceptionDates: () => import(/* webpackChunkName: "activity-form" */ '@/components/scoped/activity/form/fields/OpeningHours/ExceptionDates.vue'),
  },

  data: () => ({
    recurringDatesTotal: 0,
    recurringDatesLimmit: 25,
    recurringDatesMore: false,
  }),

  computed: {
    ...mapFields(['activityType', 'dates']),
    dateType: {
      get() {
        return this.dates.type;
      },
      set(value) {
        this.dates.type = value;
      },
    },
    dateData() {
      return this.dates.data;
    },
    dateToday() {
      return dayjs().format('YYYY-MM-DD');
    },
    recurringDates() {
      const rule = new RRule({
        ...this.dateData.rule,
        dtstart: this.dateData.rule.dtstart,
        until: this.dateData.rule.until,
      });

      const dates = _.map(rule.all(), (date) => dayjs(date));
      const exceptionDates = _.map(this.dateData.exceptionDates, (date) => dayjs(date));
      _.forEach(exceptionDates, (exceptionDate) => _.remove(dates, (date) => date.isSame(exceptionDate, 'date')));

      const returnData = _.take(dates, this.recurringDatesLimmit);
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.recurringDatesMore = (dates.length > this.recurringDatesLimmit && returnData.length < dates.length);

      return returnData;
    },
  },

  watch: {
    dates: {
      handler(value) {
        if (value === null) {
          const now = dayjs();
          const from = now.hour(now.hour() + Math.round(now.minute() / 60)).minute(0).format('HH:mm');
          const to = now.hour(now.hour() + Math.round(now.minute() / 60)).minute(0).add(1, 'hour').format('HH:mm');

          this.dates = {
            type: 'SINGLE',
            data: {
              date: dayjs(`${now.format('YYYY-MM-DD')} ${from}:00`).toDate(),
              from,
              to,
              deadline: null,
            },
          };
        }
      },
      immediate: true,
    },
    'dates.data': {
      handler(value) {
        switch (this.dateType) {
          case 'SINGLE': {
            const date = dayjs(value.date);
            this.dates.last = dayjs(`${date.format('YYYY-MM-DD')} ${value.to}:00`).toDate();
            break;
          }
          case 'MULTIPLE': {
            if (value.dates.length <= 0) {
              this.dates.last = null;
              return;
            }

            const lastDateObject = value.dates[value.dates.length - 1];
            const date = dayjs(lastDateObject.date);
            this.dates.last = dayjs(`${date.format('YYYY-MM-DD')} ${lastDateObject.to}:00`).toDate();
            break;
          }
          case 'RECURRING': {
            const rule = new RRule({
              ...value.rule,
              dtstart: value.rule.dtstart,
              until: value.rule.until,
            });

            const dates = _.map(rule.all(), (date) => dayjs(date));
            const exceptionDates = _.map(value.exceptionDates, (date) => dayjs(date));
            _.forEach(exceptionDates, (exceptionDate) => _.remove(dates, (date) => date.isSame(exceptionDate, 'date')));

            const lastDate = dates[dates.length - 1];
            this.dates.last = dayjs(`${lastDate.format('YYYY-MM-DD')} ${value.to}:00`).toDate();
            break;
          }
          case 'OPENINGTIMES': {
            if (!value.until || value.until === null) {
              this.dates.last = null;
              return;
            }

            const date = dayjs(value.until);
            this.dates.last = dayjs(`${date.format('YYYY-MM-DD')} 00:00`).toDate();
            break;
          }
          case 'REQUEST': {
            if (value.until === null) {
              this.dates.last = null;
              return;
            }

            const date = dayjs(value.until);
            this.dates.last = dayjs(`${date.format('YYYY-MM-DD')} 00:00`).toDate();
            break;
          }
          default: {
            this.dates.last = null;
            break;
          }
        }
      },
      immediate: true,
      deep: true,
    },
    dateType: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.recurringDatesLimmit = 25;

          const now = dayjs();
          const from = now.hour(now.hour() + Math.round(now.minute() / 60)).minute(0).format('HH:mm');
          const to = now.hour(now.hour() + Math.round(now.minute() / 60)).minute(0).add(1, 'hour').format('HH:mm');

          switch (newVal) {
            case 'SINGLE':
              this.dates.data = {
                date: dayjs(`${now.format('YYYY-MM-DD')} ${from}:00`).toDate(),
                from,
                to,
                deadline: null,
              };
              break;
            case 'MULTIPLE':
              this.dates.data = {
                dates: [],
                deadline: null,
                startAnytime: this.activityType !== 'COURSE',
              };
              break;
            case 'RECURRING':
              this.dates.data = {
                exceptionDates: [],
                rule: {
                  dtstart: dayjs(`${now.format('YYYY-MM-DD')} ${from}:00`).toDate(),
                  until: dayjs(`${now.format('YYYY-MM-DD')} ${to}:00`).add(6, 'month').toDate(),
                  freq: RRule.DAILY,
                  interval: 1,
                },
                from,
                to,
                deadline: null,
                startAnytime: this.activityType !== 'COURSE',
              };
              break;
            case 'OPENINGTIMES':
              this.dates.data = {
                exceptionDates: [],
                dtstart: null,
                until: null,
                dates: [],
                deadline: null,
              };

              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < 7; i++) {
                this.dates.data.dates.push({ open: false, times: [] });
              }
              break;
            case 'REQUEST':
              this.dates.data = {
                value: '',
                dtstart: null,
                until: null,
              };
              break;
            default:
              this.dates.data = null;
              break;
          }
        }
      },
      immediate: false,
    },
  },

  filters: {
    formatDate(value) {
      if (value) {
        return value.format('dd, DD.MM.YYYY');
      }
      return null;
    },
  },

  methods: {
    updateDateData(data) {
      switch (this.dateType) {
        case 'MULTIPLE': {
          this.dates.data.dates = data;
          this.sortMultipleDates(true);
          break;
        }
        default:
          this.dates.data = { ...this.dates.data, ...data };
          break;
      }
    },
    changeDates(date) {
      const now = dayjs();
      const from = now.hour(now.hour() + Math.round(now.minute() / 60)).minute(0).format('HH:mm');
      const to = now.hour(now.hour() + Math.round(now.minute() / 60)).minute(0).add(1, 'hour').format('HH:mm');

      this.dates.data.dates.push({ date: dayjs(`${date} ${from}:00`).toDate(), from, to });
      this.sortMultipleDates();
    },
    sortMultipleDates(sortOnlyByDate = false) {
      this.dates.data.dates.sort((a, b) => {
        const dateA = sortOnlyByDate ? dayjs(a.date).format('YYYY-MM-DD') : a.date;
        const dateB = sortOnlyByDate ? dayjs(b.date).format('YYYY-MM-DD') : b.date;
        if (dateA < dateB) return -1;
        if (dateA > dateB) return 1;
        return 0;
      });
    },
    removeDate(index) {
      this.dates.data.dates = this.dates.data.dates.filter((date, key) => key !== index);
    },
    excludeDate(date) {
      this.dateData.exceptionDates.push(date.toDate());
    },
    getDatesOnly(dates) {
      return _.map(dates, ({ date }) => dayjs(date).format('YYYY-MM-DD'));
    },
    getWeekDay(date) {
      const index = Number(date.format('d'));
      return index > 0 ? index - 1 : 6;
    },
  },
};
</script>
