<template>
  <v-row>
    <v-col cols="12" sm="6">
      <h2 class="title">Kategorisierung</h2>
      <p class="text-body-2">
        Weise deinem Angebot die am ehesten zutreffenden Begriffe und Attribute zu.
        <br />Dies erlaubt dem Benutzer eine effiziente Suche.
      </p>
      <p class="text-body-2">Dein Angebot erscheint für den Benutzer durch Anwählen der jeweiligen Filtereinstellung.</p>
    </v-col>
    <v-col cols="12" sm="6">
      <ValidationProvider name="Kategorie" rules="required" slim v-slot="{ errors }">
        <v-autocomplete
          label="Kategorie"
          v-model="category"
          :loading="loadingCategories"
          :items="categories"
          item-text="name.de"
          item-value="path"
          cache-items
          :error-messages="errors"
          :readonly="activityType === 'SHOP'"
          :disabled="activityType === 'SHOP'"
        />
      </ValidationProvider>
      <ValidationProvider name="Unterkategorie" rules="required" slim v-slot="{ errors }">
        <v-autocomplete label="Unterkategorie" v-model="subCategory" :loading="loadingSubcategories" :items="subcategories" item-text="name.de" item-value="path" :error-messages="errors" />
      </ValidationProvider>
      <template v-if="locationType !== 'ONLINE'">
        <ValidationProvider name="Veranstaltungsort" rules="required" slim v-slot="{ errors }">
          <v-autocomplete label="Ort" v-model="venue" :items="venueItems" :loading="loadingVenues" :filter="searchVenueItems" item-text="name" item-value="path" :error-messages="errors">
            <template v-slot:selection="data">
              {{ data.item.name }}
              <span class="caption grey--text ml-1" v-if="data.item.address">&nbsp;{{ data.item.address }}, {{ data.item.zip }} {{ data.item.city }}</span>
            </template>
            <template v-slot:item="data">
              <v-list-item-content>
                <v-list-item-title>{{ data.item.name }}</v-list-item-title>
                <v-list-item-subtitle v-if="data.item.address">{{ data.item.address }}, {{ data.item.zip }} {{ data.item.city }}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </ValidationProvider>
        <template v-if="venue === 'NEW'">
          <ValidationProvider name="Name" rules="required" v-slot="{ errors }">
            <v-text-field label="Name der Organisation" v-model="venueName" :error-messages="errors" />
          </ValidationProvider>
          <ValidationProvider name="Adresse" rules="required" v-slot="{ errors }">
            <v-text-field label="Adresse" v-model="venueAddress" :error-messages="errors" />
          </ValidationProvider>
          <v-row no-gutters>
            <v-col cols="4" class="pr-3">
              <ValidationProvider name="PLZ" rules="required" v-slot="{ errors }">
                <v-text-field label="PLZ" v-model="venueZip" :error-messages="errors" />
              </ValidationProvider>
            </v-col>
            <v-col cols="8" class="pl-3">
              <ValidationProvider name="Ort" rules="required" v-slot="{ errors }">
                <v-text-field label="Ort" v-model="venueCity" :error-messages="errors" />
              </ValidationProvider>
            </v-col>
          </v-row>
        </template>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { ValidationProvider } from 'vee-validate';

const { mapFields } = createHelpers({
  getterType: 'activity/field',
  mutationType: 'activity/updateField',
});

export default {
  components: { ValidationProvider },

  data: () => ({
    ignoreCategoryChange: false,
  }),

  computed: {
    ...mapGetters({
      activity: 'activity/get',
      venues: 'venue/all',
      categories: 'category/all',
      subcategories: 'category/subcategory/all',
      loadingVenues: 'venue/loading',
      loadingCategories: 'category/loading',
      loadingSubcategories: 'category/subcategory/loading',
    }),
    ...mapFields(['activityType', 'locationType', 'category', 'subCategory', 'dates', 'venue', 'venueName', 'venueAddress', 'venueZip', 'venueCity']),
    venueItems() {
      return [{ name: 'Neuen Veranstaltungsort erstellen', path: 'NEW' }, ...this.venues];
    },
  },

  watch: {
    venues: {
      handler(value) {
        if (this.venue === '' && (value.length === 1 || this.locationType === 'ONLINE')) {
          const venue = value.find((v) => v.defaultVenue === true);
          if (venue) {
            this.venue = venue.path;
          }
        }
      },
      immediate: true,
    },
    activity: {
      handler(value) {
        if (value) {
          this.ignoreCategoryChange = true;
        }
      },
      immediate: true,
    },
    categories: {
      handler(value) {
        if (value.length > 0 && !this.category) {
          const defaultCategory = value.find((category) => category.defaultActivityType.includes(this.activityType));
          if (defaultCategory) {
            this.category = defaultCategory.path;
          }
        }
      },
      immediate: true,
    },
    category: {
      handler(value, oldValue) {
        if (this.ignoreCategoryChange || !oldValue) {
          this.ignoreCategoryChange = false;
        } else {
          this.subCategory = '';
          this.$store.commit('category/subcategory/resetAll');
        }

        if (value) {
          this.$store.dispatch('category/subcategory/loadAllByCategoryPath', (typeof value === 'object' ? value.path : value));
        }
      },
      immediate: true,
    },
  },

  methods: {
    searchVenueItems(item, queryText) {
      if (item.path === 'NEW') return true;

      const name = item.name.toLowerCase();
      const address = item.address.toLowerCase();
      const searchText = queryText.toLowerCase();
      return name.indexOf(searchText) > -1 || address.indexOf(searchText) > -1;
    },
    onSubmit() {
      const DATA = {
        category: this.category,
        subCategory: this.subCategory,
        venue: this.venue,
      };

      if (this.venue === 'NEW') {
        DATA.venueName = this.venueName;
        DATA.venueAddress = this.venueAddress;
        DATA.venueZip = this.venueZip;
        DATA.venueCity = this.venueCity;
      }

      return new Promise((res) => res(DATA));
    },
    loadData() {
      this.$store.dispatch('venue/loadAllByOrganisation');
      this.$store.dispatch('category/loadAll');
    },
    destroyData() {
      this.$store.commit('venue/resetAll');
      this.$store.commit('category/resetAll');
      this.$store.commit('category/subcategory/resetAll');
    },
  },

  mounted() {
    this.loadData();
  },

  destroyed() {
    this.destroyData();
  },
};
</script>
