<template>
  <div>
    <v-switch v-model="deadlineVisible" label="Anmeldung erforderlich" hide-details />
    <template v-if="deadlineVisible">
      <v-radio-group v-model="deadlineType">
        <v-radio label="bis zu einem bestimmten Datum" value="DATE" />
        <v-radio label="bis Anzahl Tag/e vor Veranstaltungsdatum" value="INTERVAL" />
      </v-radio-group>
    </template>
    <template v-if="deadlineVisible && deadlineType === 'DATE'">
      <v-menu v-model="dateMenu" :close-on-content-click="false" offset-y min-width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field v-model="dateFormatted" label="Datum" readonly v-bind="attrs" v-on="on" />
        </template>
        <v-date-picker v-model="date" :min="dateToday" first-day-of-week="1" no-title @input="dateMenu = false" />
      </v-menu>
    </template>
    <template v-if="deadlineVisible && deadlineType === 'INTERVAL'">
      <ValidationProvider name="Anzahl Tag/e" rules="required|numeric|min_value:0" slim v-slot="{ errors }">
        <v-text-field label="Anzahl Tag/e" type="number" min="0" step="1" v-model="number" :error-messages="errors" />
      </ValidationProvider>
    </template>
    <template v-if="deadlineVisible">
      <v-text-field v-model="data.deadline.contact" label="Kontaktangaben" />
    </template>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

dayjs.locale('de');

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  components: { ValidationProvider },

  data: () => ({
    dateMenu: false,
  }),

  computed: {
    deadlineVisible: {
      get() {
        return !!this.$props.data.deadline;
      },
      set(value) {
        if (value) {
          this.$props.data.deadline = {
            type: 'DATE',
            value: dayjs().hour(0).minute(0).toDate(),
            contact: null,
          };
        } else {
          this.$props.data.deadline = null;
        }
      },
    },
    deadlineType: {
      get() {
        if (this.$props.data.deadline) {
          return this.$props.data.deadline.type;
        }
        return null;
      },
      set(value) {
        if (this.$props.data.deadline) {
          this.$props.data.deadline.type = value;
        }
      },
    },
    dateToday() {
      return dayjs().format('YYYY-MM-DD');
    },
    date: {
      get() {
        if (this.deadlineType !== 'DATE') {
          return null;
        }

        return dayjs(this.$props.data.deadline.value).format('YYYY-MM-DD');
      },
      set(value) {
        const data = dayjs(`${value} 00:00`).toDate();
        this.$props.data.deadline.value = data;
      },
    },
    dateFormatted() {
      if (this.deadlineType !== 'DATE') {
        return null;
      }
      return dayjs(this.$props.data.deadline.value).format('DD.MM.YYYY');
    },
    number: {
      get() {
        return this.$props.data.deadline.value;
      },
      set(value) {
        this.$props.data.deadline.value = parseInt(value, 10) >= 0 ? parseInt(value, 10) : null;
      },
    },
  },

  watch: {
    deadlineType: {
      handler(value) {
        if (!value) {
          return;
        }

        switch (value) {
          case 'INTERVAL': {
            this.$props.data.deadline.value = 7;
            break;
          }
          default: {
            this.$props.data.deadline.value = dayjs().hour(0).minute(0).second(0)
              .toDate();
            break;
          }
        }
      },
    },
  },
};
</script>
