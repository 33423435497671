<template>
  <v-row>
    <v-col cols="12" sm="6" offset-sm="6">
      <template v-if="urlTypesItems.length > 1">
        <v-row v-for="(url, index) in urls" :key="`url-${index}`">
          <v-col cols="4" class="flat-col">
            <ValidationProvider name="Typ" rules="required" slim v-slot="{ errors }">
              <v-select label="Typ" v-model="url.type" :items="urlTypesItems" dense :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col class="flat-col">
            <ValidationProvider name="Url" rules="required|url" slim v-slot="{ errors }">
              <v-text-field label="Url" type="url" v-model="url.value" dense :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <v-col cols="auto" class="flat-col">
            <v-btn small icon @click="removeUrl(index)" v-if="urls.length > 1">
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn small icon @click="addUrl" v-if="urls.length - 1 === index">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <ValidationProvider name="Website" rules="url" slim v-slot="{ errors }">
          <v-text-field :label="urlTypesItems[0].text" type="url" v-model="urls[0].value" dense :error-messages="errors" />
        </ValidationProvider>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { createHelpers } from 'vuex-map-fields';
import { ValidationProvider } from 'vee-validate';
import UrlTypes from '@/helpers/urlTypes';

const { mapFields } = createHelpers({
  getterType: 'activity/field',
  mutationType: 'activity/updateField',
});

export default {
  components: { ValidationProvider },

  computed: {
    ...mapFields(['activityType', 'locationType', 'urls']),
    urlTypesItems() {
      if (this.activityType === 'SHOP' && this.locationType !== 'LOCATION') {
        return UrlTypes.filter((type) => type.value === 'WEBSITE' || type.value === 'SHOP');
      }
      if (this.activityType !== 'SHOP' && this.locationType !== 'LOCATION') {
        return UrlTypes.filter((type) => type.value !== 'SHOP');
      }
      return UrlTypes.filter((type) => type.value === 'WEBSITE');
    },
  },

  watch: {
    urls: {
      handler(value) {
        if (!value || value.length <= 0) {
          this.urls.push({ type: '', value: '' });
        }
      },
      immediate: true,
    },
    urlTypesItems: {
      handler(value) {
        if (value.length === 1 && this.urls.length > 0) {
          if (this.urls[0].value === '') {
            this.urls[0].type = 'WEBSITE';
          } else {
            const urls = this.urls.filter((url) => url.type === 'WEBSITE');
            if (urls.length <= 0) {
              this.urls[0] = { type: 'WEBSITE', value: '' };
            } else {
              this.urls = urls;
            }
          }
        } else if (this.urls.length > 0 && this.urls[0].value === '') {
          if (this.activityType === 'SHOP') {
            this.urls[0].type = 'SHOP';
          } else {
            this.urls[0].type = '';
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    addUrl() {
      this.urls.push({ type: '', value: '' });
    },
    removeUrl(index) {
      this.urls = this.urls.slice(0, index).concat(this.urls.slice(index + 1, this.urls.length));
    },
  },
};
</script>
