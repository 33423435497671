<template>
  <fragment>
    <v-menu v-model="dtstartMenu" :close-on-content-click="false" offset-y min-width="290px">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-model="dtstartFormatted" label="Beginn Veranstaltungsreihe" readonly v-bind="attrs" v-on="on" />
      </template>
      <v-date-picker v-model="dtstart" :min="dateToday" :max="until" first-day-of-week="1" no-title @input="dtstartMenu = false" />
    </v-menu>
    <v-menu v-model="untilMenu" :close-on-content-click="false" offset-y min-width="290px">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-model="untilFormatted" label="Ende Veranstaltungsreihe" readonly v-bind="attrs" v-on="on" />
      </template>
      <v-date-picker v-model="until" :min="dtstart" first-day-of-week="1" no-title @input="untilMenu = false" />
    </v-menu>
    <v-select label="Wiederholung" :items="freqItems" v-model="freq">
      <template v-slot:selection="{ item }">{{ getFreqText(item) }}</template>
      <template v-slot:item="{ item }">{{ getFreqText(item) }}</template>
    </v-select>
    <template v-if="freq === 'CUSTOM'">
      <v-row class="fix-row-space">
        <v-col cols="6">
          <v-text-field label="Wiederhole jeden" v-model="interval" type="number" min="1" class="textfield-select">
            <template slot="append">
              <v-select :items="freqCustomItems" v-model="freqCustom" hide-details />
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="6">
          <template v-if="freqCustom === 'WEEKLY'">
            <v-select label="Wiederholen am" :items="weekDays" v-model="byweekdayCustom" multiple />
          </template>
          <template v-if="freqCustom === 'MONTHLY'">
            <v-select :items="monthlyRecurrenceItems" v-model="monthlyRecurrence">
              <template v-slot:selection="{ item }">{{ getMonthlyRecurrenceText(item) }}</template>
              <template v-slot:item="{ item }">{{ getMonthlyRecurrenceText(item) }}</template>
            </v-select>
          </template>
        </v-col>
      </v-row>
    </template>
    <v-row class="fix-row-space">
      <v-col cols="6">
        <ValidationProvider name="Von" rules="required" slim v-slot="{ errors }">
          <v-text-field label="Von" type="time" v-model="from" :error-messages="errors" />
        </ValidationProvider>
      </v-col>
      <v-col cols="6">
        <ValidationProvider name="Bis" rules="required" slim v-slot="{ errors }">
          <v-text-field label="Bis" type="time" v-model="data.to" :error-messages="errors" />
        </ValidationProvider>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { RRule } from 'rrule';
import { ValidationProvider } from 'vee-validate';

dayjs.locale('de');

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  components: { ValidationProvider },

  data: () => ({
    freqItems: ['DAILY', 'WEEKLY', 'MONTHLY', 'YEARLY', 'CUSTOM'],
    freqCustomItems: [{ value: 'DAILY', text: 'Tag' }, { value: 'WEEKLY', text: 'Woche' }, { value: 'MONTHLY', text: 'Monat' }, { value: 'YEARLY', text: 'Jahr' }],
    weekDays: [{ value: 0, text: 'Mo' }, { value: 1, text: 'Di' }, { value: 2, text: 'Mi' }, { value: 3, text: 'Do' }, { value: 4, text: 'Fr' }, { value: 5, text: 'Sa' }, { value: 6, text: 'So' }],
    monthlyRecurrenceItems: ['MONTHLY_bymonthday', 'MONTHLY_byweekday'],
    dtstartMenu: false,
    untilMenu: false,
    freqCustomChange: false,
  }),

  computed: {
    dateObj() {
      return dayjs(`${this.dtstart} ${this.from}:00`);
    },
    from: {
      get() {
        return this.$props.data.from;
      },
      set(value) {
        const dtstart = dayjs(`${this.dtstart} ${value}:00`).toDate();
        const data = { rule: { ...this.$props.data.rule, dtstart }, from: value };

        if (this.$props.data.to < value) {
          data.to = value;
        }
        this.$emit('update:data', data);
      },
    },
    dateToday() {
      return dayjs().format('YYYY-MM-DD');
    },
    dtstart: {
      get() {
        return dayjs(this.$props.data.rule.dtstart).format('YYYY-MM-DD');
      },
      set(value) {
        const date = dayjs(`${value} ${this.data.from}:00`);
        if (this.freq === 'WEEKLY') {
          this.$emit('update:data', { rule: { ...this.$props.data.rule, dtstart: date.toDate(), byweekday: [this.getDayOfWeek(date)] } });
        } else if (this.freq === 'CUSTOM' && this.freqCustom === 'MONTHLY') {
          if (this.monthlyRecurrence === 'MONTHLY_bymonthday') {
            this.$emit('update:data', { rule: { ...this.$props.data.rule, dtstart: date.toDate(), bymonthday: [dayjs(value).date()] } });
          } else {
            this.$emit('update:data', {
              rule: {
                ...this.$props.data.rule, dtstart: date.toDate(), byweekday: [this.getDayOfWeek(date)], bysetpos: [this.getNthOfMonth()],
              },
            });
          }
        } else {
          this.$emit('update:data', { rule: { ...this.$props.data.rule, dtstart: date.toDate() } });
        }
      },
    },
    dtstartFormatted() {
      return dayjs(this.$props.data.rule.dtstart).format('DD.MM.YYYY');
    },
    until: {
      get() {
        return dayjs(this.$props.data.rule.until).format('YYYY-MM-DD');
      },
      set(value) {
        const date = dayjs(`${value} ${this.data.to}:00`);
        this.$emit('update:data', { rule: { ...this.$props.data.rule, until: date.toDate() } });
      },
    },
    untilFormatted() {
      return dayjs(this.$props.data.rule.until).format('DD.MM.YYYY');
    },
    freq: {
      get() {
        if (this.$props.data.rule.freq === RRule.DAILY && this.$props.data.rule.interval === 1 && !this.freqCustomChange) {
          return 'DAILY';
        }
        // eslint-disable-next-line max-len
        if (this.$props.data.rule.freq === RRule.WEEKLY && this.$props.data.rule.interval === 1 && (this.$props.data.rule.byweekday.length <= 1 && this.$props.data.rule.byweekday.includes(this.getDayOfWeek(this.dateObj))) && this.$props.data.rule.interval === 1 && !this.freqCustomChange) {
          return 'WEEKLY';
        }
        if (this.$props.data.rule.freq === RRule.MONTHLY && this.$props.data.rule.interval === 1 && (!this.$props.data.rule.byweekday && !this.$props.data.rule.bysetpos) && !this.freqCustomChange) {
          return 'MONTHLY';
        }
        if (this.$props.data.rule.freq === RRule.YEARLY && this.$props.data.rule.interval === 1 && !this.freqCustomChange) {
          return 'YEARLY';
        }

        return 'CUSTOM';
      },
      set(value) {
        this.freqCustomChange = false;
        if (value === 'DAILY') {
          this.$emit('update:data', {
            rule: {
              freq: RRule.DAILY,
              interval: 1,
              dtstart: this.$props.data.rule.dtstart,
              until: this.$props.data.rule.until,
            },
          });
        } else if (value === 'WEEKLY') {
          this.$emit('update:data', {
            rule: {
              freq: RRule.WEEKLY,
              interval: 1,
              byweekday: [this.getDayOfWeek(this.dateObj)],
              dtstart: this.$props.data.rule.dtstart,
              until: this.$props.data.rule.until,
            },
          });
        } else if (value === 'MONTHLY') {
          this.$emit('update:data', {
            rule: {
              freq: RRule.MONTHLY,
              interval: 1,
              dtstart: this.$props.data.rule.dtstart,
              until: this.$props.data.rule.until,
            },
          });
        } else if (value === 'YEARLY') {
          this.$emit('update:data', {
            rule: {
              freq: RRule.YEARLY,
              interval: 1,
              dtstart: this.$props.data.rule.dtstart,
              until: this.$props.data.rule.until,
            },
          });
        } else if (value === 'CUSTOM') {
          this.freqCustomChange = true;
          this.$emit('update:data', {
            rule: {
              freq: RRule.WEEKLY,
              interval: 1,
              byweekday: [this.getDayOfWeek(this.dateObj)],
              dtstart: this.$props.data.rule.dtstart,
              until: this.$props.data.rule.until,
            },
          });
        }
      },
    },
    freqCustom: {
      get() {
        if (this.$props.data.rule.freq === RRule.DAILY) {
          return 'DAILY';
        } if (this.$props.data.rule.freq === RRule.WEEKLY) {
          return 'WEEKLY';
        } if (this.$props.data.rule.freq === RRule.MONTHLY) {
          return 'MONTHLY';
        }
        return 'YEARLY';
      },
      set(value) {
        this.freqCustomChange = true;
        if (value === 'WEEKLY') {
          this.$emit('update:data', {
            rule: {
              freq: RRule.WEEKLY,
              interval: this.interval,
              byweekday: [this.getDayOfWeek(this.dateObj)],
              dtstart: this.$props.data.rule.dtstart,
              until: this.$props.data.rule.until,
            },
          });
        } else if (value === 'MONTHLY') {
          this.$emit('update:data', {
            rule: {
              freq: RRule.MONTHLY,
              interval: this.interval,
              bymonthday: [this.dateObj.date()],
              dtstart: this.$props.data.rule.dtstart,
              until: this.$props.data.rule.until,
            },
          });
        } else {
          this.$emit('update:data', {
            rule: {
              freq: value === 'DAILY' ? RRule.DAILY : RRule.YEARLY,
              interval: this.interval,
              dtstart: this.$props.data.rule.dtstart,
              until: this.$props.data.rule.until,
            },
          });
        }
      },
    },
    byweekdayCustom: {
      get() {
        return this.$props.data.rule.byweekday;
      },
      set(newValue) {
        this.freqCustomChange = true;
        this.$props.data.rule.byweekday = newValue.sort();
      },
    },
    interval: {
      get() {
        return this.$props.data.rule.interval;
      },
      set(newValue) {
        this.freqCustomChange = true;
        const number = parseInt(newValue, 10);
        this.$emit('update:data', {
          rule: {
            ...this.$props.data.rule,
            interval: !number || number < 1 ? 1 : number,
          },
        });
      },
    },
    monthlyRecurrence: {
      get() {
        if (!this.$props.data.rule.bymonthday && this.$props.data.rule.byweekday && this.$props.data.rule.bysetpos) {
          return 'MONTHLY_byweekday';
        }
        return 'MONTHLY_bymonthday';
      },
      set(value) {
        this.freqCustomChange = true;
        if (value === 'MONTHLY_byweekday') {
          this.$emit('update:data', {
            rule: {
              freq: RRule.MONTHLY,
              interval: this.interval,
              byweekday: [this.getDayOfWeek(this.dateObj)],
              bysetpos: [this.getNthOfMonth()],
              dtstart: this.$props.data.rule.dtstart,
              until: this.$props.data.rule.until,
            },
          });
        } else {
          this.$emit('update:data', {
            rule: {
              freq: RRule.MONTHLY,
              interval: this.interval,
              bymonthday: [this.dateObj.date()],
              dtstart: this.$props.data.rule.dtstart,
              until: this.$props.data.rule.until,
            },
          });
        }
      },
    },
  },

  methods: {
    getDayOfWeek(date) {
      const index = Number(date.format('d'));
      return index > 0 ? index - 1 : 6;
    },
    getNthOfMonth() {
      const date = this.dateObj;
      let firstWeekDayOfMonth = date.clone().startOf('month').date(1);
      firstWeekDayOfMonth = firstWeekDayOfMonth.day() <= date.day() ? firstWeekDayOfMonth.day(date.day()) : firstWeekDayOfMonth.day(date.day() + 7);
      const diffDays = date.date() - firstWeekDayOfMonth.date();
      return (diffDays / 7) + 1;
    },
    getFreqText(option) {
      switch (option) {
        case 'DAILY':
          return 'Täglich';
        case 'WEEKLY':
          return this.dateObj ? `Wöchentlich am ${this.dateObj.format('dddd')}` : 'Wöchentlich';
        case 'MONTHLY':
          return this.dateObj ? `Monatlich am ${this.dateObj.format('DD.')}` : 'Monatlich';
        case 'YEARLY':
          return this.dateObj ? `Jährlich am ${this.dateObj.format('DD. MMM')}` : 'Jährlich';
        case 'CUSTOM':
          return 'Benutzerdefiniert';
        default:
          return 'Keine Wiederholung';
      }
    },
    getMonthlyRecurrenceText(option) {
      switch (option) {
        case 'MONTHLY_byweekday':
          return `Monatlich am ${this.getNthOfMonth()}. ${this.dateObj.format('dddd')}`;
        default:
          return `Monatlich am ${this.dateObj.format('D')}.`;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fix-row-space {
  margin-top: -12px;
}

.textfield-select.v-text-field {
  &::v-deep .v-input__append-inner {
    margin-top: 0;

    .v-input {
      margin-top: 0;
      padding-top: 0;
    }

    .v-input__append-inner {
      margin-top: 4px;
    }

    .v-text-field {
      > .v-input__control > .v-input__slot::before {
        display: none;
      }

      &.v-input--is-focused > .v-input__control > .v-input__slot::after {
        display: none;
      }
    }
  }

  &.v-text-field--enclosed {
    &::v-deep .v-input__append-inner {
      margin-top: 12px;

      .v-input__append-inner {
        margin-top: 4px;
      }
    }
  }
}
</style>
