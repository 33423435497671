<template>
  <fragment>
    <ValidationProvider name="Kontaktangaben" rules="required" slim v-slot="{ errors }">
      <v-text-field label="Kontaktangaben" v-model="data.value" :error-messages="errors" />
    </ValidationProvider>
    <v-row class="fix-row-space">
      <v-col cols="12" sm="6">
        <v-menu v-model="dtstartMenu" :close-on-content-click="false" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="dtstartFormatted" label="Von" readonly clearable v-bind="attrs" v-on="on" />
          </template>
          <v-date-picker v-model="dtstart" :min="dateToday" first-day-of-week="1" no-title :max="until" @input="dtstartMenu = false" />
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6">
        <v-menu v-model="untilMenu" :close-on-content-click="false" offset-y min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="untilFormatted" label="Bis" readonly clearable v-bind="attrs" v-on="on" />
          </template>
          <v-date-picker v-model="until" :min="dtstart || dateToday" first-day-of-week="1" no-title @input="untilMenu = false" />
        </v-menu>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

dayjs.locale('de');

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  components: { ValidationProvider },

  data: () => ({
    dtstartMenu: false,
    untilMenu: false,
  }),

  computed: {
    dateToday() {
      return dayjs().format('YYYY-MM-DD');
    },
    dtstart: {
      get() {
        return this.data.dtstart ? dayjs(this.data.dtstart).format('YYYY-MM-DD') : null;
      },
      set(value) {
        this.data.dtstart = dayjs(`${value} 00:00`).toDate();
      },
    },
    until: {
      get() {
        return this.data.until ? dayjs(this.data.until).format('YYYY-MM-DD') : null;
      },
      set(value) {
        this.data.until = dayjs(`${value} 00:00`).toDate();
      },
    },
    dtstartFormatted: {
      get() {
        return this.data.dtstart ? dayjs(this.data.dtstart).format('DD.MM.YYYY') : '';
      },
      set(value) {
        this.data.dtstart = value;
      },
    },
    untilFormatted: {
      get() {
        return this.data.until ? dayjs(this.data.until).format('DD.MM.YYYY') : '';
      },
      set(value) {
        this.data.until = value;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.fix-row-space {
  margin-top: -12px;
}
</style>
