<template>
  <v-row>
    <v-col cols="12" sm="6">
      <h2 class="title">Angebot</h2>
      <p class="text-body-2">Gib in groben Zügen an um was es sich bei deinem Angebot handelt.</p>
      <p class="text-body-2">
        Der Titel taucht in der Ergebnisliste als Überschrift auf.
        <br />Der Beschrieb und der Link sind für den Leser auf der Detailseite nützliche Informationen.
      </p>
    </v-col>
    <v-col cols="12" sm="6">
      <ValidationProvider name="Art des Angebots" rules="required" slim v-slot="{ errors }">
        <v-select label="Art des Angebots" v-model="activityType" :items="activityTypesItems" :error-messages="errors" />
      </ValidationProvider>
      <ValidationProvider name="Typ" rules="required" slim v-slot="{ errors }">
        <v-radio-group v-model="locationType" row>
          <v-radio v-for="item in locationTypesItems" :key="`location-type-${item.value}`" :label="item.text" :value="item.value" :error-messages="errors" />
        </v-radio-group>
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import { ValidationProvider } from 'vee-validate';
import ActivityTypes from '@/helpers/activityTypes';
import LocationTypes from '@/helpers/locationTypes';

const { mapFields } = createHelpers({
  getterType: 'activity/field',
  mutationType: 'activity/updateField',
});

export default {
  components: { ValidationProvider },

  data: () => ({
    activityTypesItems: ActivityTypes,
    locationTypesItems: LocationTypes,
  }),

  computed: {
    ...mapGetters({
      categories: 'category/all',
    }),
    ...mapFields(['activityType', 'locationType', 'category', 'dates']),
  },

  watch: {
    activityType: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (newValue === 'SHOP') {
            const category = this.categories.find((cat) => cat.defaultActivityType.includes(newValue));
            this.category = category ? category.path : '';
            this.updateDates();
          }

          if (oldValue === 'SHOP' && newValue !== 'SHOP') {
            this.dates = {
              type: 'ANYTIME',
              data: null,
              last: null,
            };
          }
        }
      },
    },
    locationType: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          if (this.activityType === 'SHOP') {
            this.updateDates();
          }
        }
      },
    },
  },

  methods: {
    updateDates() {
      if (this.locationType === 'ONLINE') {
        this.dates = {
          type: 'ANYTIME',
          data: null,
          last: null,
        };
      } else {
        this.dates = {
          type: 'OPENINGTIMES',
          data: {
            exceptionDates: [],
            dtstart: null,
            until: null,
            dates: [],
            deadline: null,
          },
          last: null,
        };

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < 7; i++) {
          this.dates.data.dates.push({ open: false, times: [] });
        }
      }
    },
  },
};
</script>
