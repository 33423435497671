<template>
  <fragment>
    <v-row>
      <v-col cols="12" sm="6">
        <h2 class="title">Ergänze dein Angebot mit Fotos</h2>
        <p class="text-body-2">
          Wähle qualitätsvolle Bilder zu deinem Angebot. Sie vermitteln einen ersten wichtigen Eindruck. Das Titelbild erscheint in der Agenda, die weiteren in der Detailansicht des Angebots.
        </p>
      </v-col>
      <v-col cols="12" sm="6">
        <!-- <base-image-upload /> -->
        <template v-for="(image, index) in images">
          <v-row align="end" :key="index" v-if="image.status !== 'deleted'">
            <v-col cols="4">
              <v-card>
                <cld-image :publicId="image.publicId" v-if="image.publicId">
                  <cld-transformation gravity="face:center" height="200" width="200" crop="fill" />
                </cld-image>
                <v-img :src="image.image" :aspect-ratio="1" v-else-if="image.image" />
                <v-card-actions class="d-flex justify-space-between">
                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on }">
                      <v-btn small :text="!image.featured" :color="image.featured ? 'primary' : 'default'" v-on="on" @click="setFeatured(index)">Titelbild</v-btn>
                    </template>
                    <span v-if="image.featured">Ist Titelbild</span>
                    <span v-else>Als Titelbild auswählen</span>
                  </v-tooltip>
                  <v-tooltip bottom open-delay="500">
                    <template v-slot:activator="{ on }">
                      <v-btn icon small v-on="on" @click="deleteImage(index)">
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Löschen</span>
                  </v-tooltip>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col>
              <v-text-field v-model="image.alternativeText.DE" label="Bildunterschrift" class="mb-5" />
            </v-col>
          </v-row>
        </template>
        <v-row>
          <v-col cols="4">
            <v-card
              link
              ripple
              class="image-upload"
              :disabled="loading"
              :elevation="hover || dragging ? 4 : 2"
              @mouseenter="hover = true"
              @mouseleave="hover = false"
              @dragenter="dragging = true"
              @dragleave="dragging = false"
            >
              <v-icon x-large v-if="!dragging">mdi-plus</v-icon>
              <v-icon x-large v-else>mdi-cloud-upload</v-icon>
              <input type="file" @change="addImage" accept="image/png, image/jpeg" ref="fileInput" />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </fragment>
</template>

<script>
import { mapGetters } from 'vuex';
import { createHelpers } from 'vuex-map-fields';

const { mapFields } = createHelpers({
  getterType: 'activity/field',
  mutationType: 'activity/updateField',
});

export default {
  data: () => ({
    hover: false,
    dragging: false,
  }),

  computed: {
    ...mapGetters({
      loading: 'loading/get',
    }),
    ...mapFields(['images']),
  },

  methods: {
    addImage(event) {
      this.hover = false;
      this.dragging = false;
      this.$store.commit('error/clear');

      const files = event.target.files || event.dataTransfer.files;
      if (files.length !== 1) {
        this.$store.commit('error/set', { message: 'Es kann nur ein Bild hochgeladen werden.' });
        return;
      }

      const file = event.target.files[0];
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        this.$store.commit('error/set', { message: 'Es können nur Bilder vom Typ JPG/PNG hochgeladen werden.' });
        return;
      }
      if (file.size > 10485760) {
        this.$store.commit('error/set', { message: 'Das Bild ist zu gross. Es sind maximal 10 MB erlaubt.' });
        return;
      }

      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener('load', () => {
        this.images.push({
          featured: this.images.length <= 0, fileName: file.name, alternativeText: { DE: '' }, file, image: fr.result, status: 'added',
        });
        this.$refs.fileInput.value = '';
      });
    },
    deleteImage(index) {
      const data = [...this.images];
      data[index].status = 'deleted';
      if (data[index].featured) {
        data[index].featured = false;
        if (data[index + 1] && data[index + 1].status !== 'deleted') {
          data[index + 1].featured = true;
        } else if (data[index - 1] && data[index - 1].status !== 'deleted') {
          data[index - 1].featured = true;
        }
      }
      this.images = data;
    },
    setFeatured(index) {
      this.images = this.images.map((image, i) => ({
        ...image,
        featured: index === i,
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
.cld-image {
  &::v-deep img {
    max-width: 100%;
    display: block;
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
  }
}

.image-upload {
  height: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  input {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}
</style>
