import _ from 'lodash';

const data = [
  {
    text: 'Website',
    value: 'WEBSITE',
  },
  {
    text: 'Shop',
    value: 'SHOP',
  },
  {
    text: 'Streaming',
    value: 'STREAMING',
  },
  {
    text: 'Video',
    value: 'VIDEO',
  },
  {
    text: 'Download-Material',
    value: 'DOWNLOAD',
  },
];

export default data;

export const getTextByValue = (value) => {
  const item = _.find(data, ['value', value]);
  return item.text;
};
