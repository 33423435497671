var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('ValidationProvider',{attrs:{"name":"Kontaktangaben","rules":"required","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Kontaktangaben","error-messages":errors},model:{value:(_vm.data.value),callback:function ($$v) {_vm.$set(_vm.data, "value", $$v)},expression:"data.value"}})]}}])}),_c('v-row',{staticClass:"fix-row-space"},[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Von","readonly":"","clearable":""},model:{value:(_vm.dtstartFormatted),callback:function ($$v) {_vm.dtstartFormatted=$$v},expression:"dtstartFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dtstartMenu),callback:function ($$v) {_vm.dtstartMenu=$$v},expression:"dtstartMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.dateToday,"first-day-of-week":"1","no-title":"","max":_vm.until},on:{"input":function($event){_vm.dtstartMenu = false}},model:{value:(_vm.dtstart),callback:function ($$v) {_vm.dtstart=$$v},expression:"dtstart"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Bis","readonly":"","clearable":""},model:{value:(_vm.untilFormatted),callback:function ($$v) {_vm.untilFormatted=$$v},expression:"untilFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.untilMenu),callback:function ($$v) {_vm.untilMenu=$$v},expression:"untilMenu"}},[_c('v-date-picker',{attrs:{"min":_vm.dtstart || _vm.dateToday,"first-day-of-week":"1","no-title":""},on:{"input":function($event){_vm.untilMenu = false}},model:{value:(_vm.until),callback:function ($$v) {_vm.until=$$v},expression:"until"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }